.dash-header {
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 999;
}
.language-wrapper {
    .custom-dropdown.language-selector {
        position: unset;
    }
    .custom-option img {
        max-width: 32px !important;
    }
    .custom-dropdown.language-selector .css-13cymwt-control,
    .custom-dropdown.language-selector .css-t3ipsp-control {
        padding: 7px 12px;
    }
    .custom-dropdown .css-13cymwt-control {
        border-color: $neutral-color-n-80;
        min-width: 114px;
    }
    .language-wrapper .custom-dropdown.language-selector .css-13cymwt-control,
    .language-wrapper .custom-dropdown.language-selector .css-t3ipsp-control {
        min-width: 114px;
    }
    .custom-dropdown .css-t3ipsp-control {
        min-width: 114px;
    }
}
.header-action {
    display: flex;
    align-items: center;
    column-gap: 12px;
}
.back-drop-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 1;
}
.toggle-wrapper {
    position: relative;
}
.notification-wrapper {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 60px;
    overflow: hidden;
    border-radius: 24px;

    .popup-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;

        border: none;
        background-color: $neutral-color-n-96 !important;
    }
    .notify-content {
        .xsm {
            color: $neutral-color-n-30;
        }
        p.sm {
            // overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
.notify-action-btn {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    button {
        min-width: 84px;
        padding: 6px;
        border-radius: 25px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $neutral-color-n-70;
        border: 1px solid $neutral-color-n-70;
        background-color: transparent;
        &.solid {
            background-color: $neutral-color-n-70;
            color: $white;
        }
    }
}
.notify-container {
    padding: 24px;
    padding-top: 34px;
    background-color: $neutral-color-n-96;
    border-radius: 24px;
    min-width: 412px;
    width: 100%;
    max-height: 600px;
    overflow: auto;
    .notify-container-heading {
        padding-bottom: 16px;
    }
    .notify-item {
        background-color: $white;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &.seen {
            border-color: $neutral-color-n-80;
            cursor: not-allowed !important;
        }
    }
}
.notify-item {
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 16px;
    border: 2px solid $primary-color-p-50-main;
    display: flex;
    align-items: center;
    column-gap: 16px;
}
.notify-btn {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    justify-content: center;
    border: 1px solid $neutral-color-n-80;
    border-radius: 16px;
    &:hover {
        border: 1px solid $neutral-color-n-80;
    }
    img {
        width: 18px;
        margin-top: 4px;
    }
    .notify-count {
        position: absolute;
    }
}
.modal-dialog-centered {
    min-height: 100%;
}
.calling-popup-action {
    display: flex;
    align-items: center;
    max-width: 230px;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 50px;
}
.calling-toaster {
    position: fixed;
    top: 118px;
    right: 50px;
    z-index: 9999;
    transition: all 0.6s ease;
    transform: translateX(calc(100% + 50px));
    &.calling-toaster-show {
        transform: translateX(0%);
    }
    .calling-toaster-dp {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .name-dp {
        display: flex;
        gap: 8px;
        h5 {
            margin-bottom: 0px;
            font-size: 20px;
        }
        p {
            color: $primary-color-p-50-main;
            font-size: 14px;
            padding-top: 4px;
        }
    }
    .calling-toaster-action {
        display: flex;
        column-gap: 24px;
        padding-top: 10px;
        button {
            img {
                max-width: 40px;
            }
        }
    }
    .calling-toaster-inner {
        padding: 24px;
        border-radius: 16px;
        border: 2px solid $primary-color-p-50-main;
        background-color: #e5f2ff;
    }
}
.notify-count {
    width: 18px;
    height: 18px;
    background-color: $state-color-error;
    color: $white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 6px;
    left: 48%;
    border: 1px solid $white;
}
.mobile-profile-btn {
    display: flex;
}
.page-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
    img {
        max-width: 400px;
    }
    & > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }
}
// HAMBURGGER
.btn-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 25px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    transition: 0.3s ease;

    &:focus {
        outline: none;
    }

    &__text {
        margin-left: 10px;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1;
    }

    &__bars {
        display: block;
        position: relative;
        width: 30px;
        height: 3px;
        background-color: $primary-color-p-50-main;
        transition: 0.3s;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $primary-color-p-50-main;
            transition: 0.3s;
        }

        &:before {
            transform: translate(0, -11px);
        }

        &:after {
            transform: translate(0, 11px);
        }
    }

    &.menu-open {
        .btn-menu__bars {
            background-color: transparent;

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
.toggle-wrapper {
    .btn {
        border-radius: 50%;
        max-width: 64px;
        aspect-ratio: 1;
        padding: 0px;
        overflow: hidden;
        border: 1px solid $neutral-color-n-80;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .btn.show {
        border-radius: 50%;
        border-color: $neutral-color-n-50;
    }
    .dropdown-item {
        &:hover {
            img {
                filter: brightness(10);
            }
        }
        span {
            display: flex;
            align-items: center;
            column-gap: 8px;
        }
    }
}
@media (max-width: 1399px) {
    .calling-toaster {
        top: 84px;
        right: 30px;
    }
    .calling-popup-action button img {
        max-width: 60px;
    }
    .report-popup .report-dp {
        max-width: 140px;
    }
    .calling-popup-action {
        margin-top: 26px;
    }
    .calling-toaster .calling-toaster-inner {
        padding: 20px;
    }
}
@media (max-width: 1199px) {
    .page-not-found {
        img {
            max-width: 350px;
        }
    }
}
@media (max-width: 992px) {
    .calling-popup-action {
        button {
            img {
                max-width: 60px;
            }
        }
    }
    .page-not-found {
        img {
            max-width: 300px;
        }
    }
    .sidebar {
        position: absolute;
        width: 100%;
        height: calc(100% - 80px);
        top: 80px;
        transform: translateX(-100%);
        transition: all 0.2s ease;
        background-color: transparent;

        .main-sidebar {
            transform: translateX(-100%);
            transition: all 0.2s ease;
        }
        &.activeHeader {
            transform: translateX(0%);
            transition: unset;
            background-color: rgba(0, 0, 0, 0.319);
            z-index: 999;

            .main-sidebar {
                transform: translateX(0%);
            }
        }
    }
}

@media (max-width: 767px) {
    .calling-popup-action {
        button {
            img {
                max-width: 50px;
            }
        }
    }
    .notification-wrapper {
    }
    .dash-header {
        padding: 6px 12px;
        .mobile-logo {
            img {
                max-width: 60px;
            }
        }
    }
    .sidebar {
        top: 71px;
        height: calc(100% - 71px);
    }
    .header-action {
        .language-wrapper
            .custom-dropdown.language-selector
            .css-13cymwt-control,
        .language-wrapper
            .custom-dropdown.language-selector
            .css-t3ipsp-control {
            padding: 4px 10px;
        }
        .custom-dropdown .css-13cymwt-control,
        .custom-dropdown .css-16xfy0z-control,
        .custom-dropdown .css-t3ipsp-control {
            border-radius: 10px;
        }
        .notify-btn {
            width: 42px;
            height: 42px;
            border-radius: 10px;
        }
    }
}
@media (max-width: 600px) {
    .notification-wrapper {
        position: fixed;
        left: 50%;
        top: 80px;
        transform: translateX(-50%);
        width: calc(100% - 32px);
        .notify-container {
            min-width: 100%;
            padding: 16px;
        }
    }
}
